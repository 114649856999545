.volta-pagination {
  margin-top: 33px;

  span.btn-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 24px;
    height: 24px;
    margin-right: 10px;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
  }

  span.btn-pagination.num:hover {
    background-color: #d1d1d1;
    opacity: 0.5;
  }

  span.btn-pagination.selected {
    background-color: #edf6f1;
    border-radius: 5px;
  }
}
