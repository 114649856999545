.footer{
    position: fixed;
    padding-left: 10px;
    bottom: 0;
    z-index: 9999;
}

.txt-version{
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #7E7E7E;
    text-align: right;
}