.input-image {
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #eeeeee;
  flex: 1;
  height: 240px;
  overflow: hidden;
  background: #f7f9f7;

  .image {
    flex: 1;
    height: inherit;
    border-radius: inherit;
    object-fit: contain;
    width: 100%;
  }
}

.input-image.error {
  border-color: red;
}
.btn-upload {
  background-color: #ffe9d8;
  color: #f08633;
  font-weight: 600;
  font-size: 0.75rem;
  border-radius: 2rem;
  padding: 0.5rem 2rem;
}
.btn-upload:hover {
  color: #f08633;
  background-color: #f8dbc5;
}
.btn-upload:focus {
  box-shadow: 0 0 0 0.2rem rgba(240, 119, 22, 0.1);
}
