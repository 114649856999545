@keyframes showingMotion {
  0%  {transform: scale(0.7);}
  45%  {transform: scale(1.05);}
  80%  {transform: scale(0.95);}
  100% {transform: scale(1);}
}

@keyframes closingMotion {
  0%  {transform: scale(1);}
  100% {transform: scale(0);}
}

.modal.fade .modal-dialog.volta-modal.success-volta-modal {
  @media screen and (min-width: 543px) {
    animation: closingMotion .3s;
  }
}

.modal.show .modal-dialog.volta-modal.success-volta-modal {
  @media screen and (min-width: 543px) {
    transition: none;
    animation: showingMotion .3s;
  }
}

.success-volta-modal{
    .body-success-modal{
      text-align: center;
      .title-modal{
        text-align: center;
      }
    }
  
    .title-modal{
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: #212221;
    }
  
    .desc-modal{
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #585858;
    }
  
    .volta-modal-header{
      height: 20px !important;
    }
  
    .submit-content {
      display: flex;
      justify-content: center;
      padding: 20px;
      .btn-submit,
      .btn-cancel {
        @media screen and (max-width: 1024px) {
          width: 100%;
        }
        width: 40%;
        height: 40px;
  
        p {
          color: white;
          font-size: 14px;
          margin: 0;
        }
      }
  
      .btn-submit {
        @media screen and (max-width: 1024px) {
          width: 100%;
          margin: 0;
        }
        width: 60%;
        background-color: #1F9545;
        font-size: 0.75rem;
        padding: 9px 18px;
        font-weight: 600;
        color: white;
        border-radius: 50px;
        margin-left: 5%;
      }
  
      .btn-cancel {
        background-color: rgba(31, 149, 69, 0.17);
        font-size: 0.75rem;
        padding: 9px 18px;
        font-weight: 600;
        border-radius: 50px;
        p{
          color: #1F9545
        }
      }
    }
  }