.right-sidebar{
  position: fixed;
  // top: -90px;
  // right: -80px;
  z-index: 3;
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;

  .right-sidebar-content{
    background-color: white;
    padding: 32px 30px;
    width: 30vw;
    height: 100vh;

    .header-sidebar{
      margin-bottom: 41px;
      height: auto;
      
      .btn-filter-close{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border-radius: 100%;
        font-size: 15px;
        font-weight: bold;
        border: 1px solid #001B46;
      }
  
      .title{
        font-size: 20px;
        font-weight: bold;
      }
  
    }
  }
  .backdrop-filter-sidebar{
    width: 100vw;
    background-color: #6c757d;
    opacity: 0.4;
  }
}