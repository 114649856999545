.form-content-mobile {
  min-height: 50px;
  padding: 15px 30px 40px;

  .form-title {
    font-size: 12px;
    color: #126133;
    font-weight: 700;
  }

  .form-title-alternative {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 16px;
    @media screen and (max-width: 543px) {
      font-size: 14px;
    }
    @media screen and (max-width: 280px) {
      font-size: 12px;
    }
  }

  .form-data {
    // margin-top: 30px;
    margin-right: 25px;

    .form-group {
      margin-bottom: 20px;

      label {
        color: #858585;
        font-size: 10px;
      }

      input {
        height: 40px;
        margin-top: 12px;
      }

      input,
      select,
      textarea {
        font-size: 10px;
      }
    }
  }

  .info-box {
    display: flex;
    flex: 1;
    padding: 15px;
    border: 1px #eeeeee solid;
    font-size: 10px;

    background: #f1f1f1;
    border-radius: 10px;
    font-weight: 500;
    border-bottom: 1.2px solid #e0e0e0;

    .title-1,
    .title-2 {
      margin: 0;
    }

    .title-1 {
      flex: 1;
      color: #7e7e7e;
    }

    .title-2 {
      flex: 2;
      color: #212221;
      text-align: end;
    }

    &-row {
      padding-bottom: 13px;
      border-bottom: 1.2px solid #e0e0e0;
    }
  }

  .motor-volta {
    width: 197.51px;
    height: 140px;
  }

  .configuration {
    margin-top: 37px;
  }

  .btn-form-submit {
    background-color: #1f9545;
    border-radius: 5px;
    color: white;
    font-size: 12px;
    width: 100%;
    height: 46px;
    border-radius: 2rem;
    font-weight: 600;
  }

  .btn-form-submit:hover {
    color: white;
    background-color: #1d8a4c;
  }

  .btn-form-secondary {
    background-color: rgba(31, 149, 69, 0.17);
    border-radius: 5px;
    color: #1f9545;
    font-size: 12px;
    width: 100%;
    height: 46px;
    border-radius: 2rem;
    font-weight: 600;
  }
  .btn-form-secondary:hover {
    color: #1f9545;
    background-color: rgba(31, 149, 68, 0.27);
  }
}
