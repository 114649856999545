.text-background {
  color: #fafafb !important;
}

.text-white-100 {
  color: #ffffff !important;
}

.text-gray-10 {
  color: rgba(0, 0, 0, 0.16) !important;
}

.text-black-20 {
  color: #393535 !important;
}

.text-blue-100 {
  color: #001b46 !important;
}

.text-black-60 {
  color: #858585 !important;
}

.text-blue-80 {
  color: #282560 !important;
}

.text-white-80 {
  color: #f1f1f1 !important;
}

.text-gray-80 {
  color: #575454 !important;
}

.text-gray-900 {
  color: #212221 !important;
}

.text-white-60 {
  color: #e3e0e0 !important;
}

.text-white-40 {
  color: #dbdbdb !important;
}

.text-green-100 {
  color: #069731 !important;
}

.text-orange-100 {
  color: #f37a20 !important;
}

.text-red-100 {
  color: #f11a1a !important;
}
