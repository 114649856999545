.dropdown-table-modal {
  .btn-submit {
    background-color: #1f9545;
    border-radius: 5px;
    color: white;
    font-size: 14px;
    width: 100%;
    height: 46px;
    border-radius: 2rem;
    font-weight: 600;
    p {
      color: white;
      font-size: 14px;
      margin: 0;
    }
  }
}

.dropdown-customer {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  height: 32px;
  justify-content: center;

  .dot {
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background-color: #858585;
    margin-top: 4px;
  }
}

.dropdown-menu {
  z-index: 9999;
  padding: 0;
  width: 220px;
  border-radius: 10px;
  border: 0;
  box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  .dropdown-item {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    height: 40px;
    line-height: 20px;
    color: #212221;
    display: flex;
    align-items: center;
  }
  .dropdown-item:hover {
    background: #e8f5ec;
  }
}

@media screen and (max-width: 543px) {
  .modal-dialog {
    margin: 0;
    min-height: -webkit-fill-available;
    align-items: flex-end;
  }
  .volta-modal .modal-content {
    border: none;
    border-radius: 20px 20px 0 0 !important;
  }
}
