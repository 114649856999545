.volta-radio{
    .form-check{
        display: table-cell;
        padding-right: 30px;
    }
    .form-check-input{
        height: 16px !important;
        width: 16px;
        margin-top: 6px !important;
    }
    .form-check-input:checked{
        background-color: #1F9545;
        border-color: #1F9545;
    }
    .form-check-label{
        padding-left: 5px;
    }
}