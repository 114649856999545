.btn {
  --bs-btn-font-weight: 600;
}
.btn-light {
  --bs-btn-color: #212121;
  --bs-btn-bg: #499b6323;
  --bs-btn-border-color: #499b6323;
  --bs-btn-hover-color: #212121;
  --bs-btn-hover-bg: #499b6348;
  --bs-btn-hover-border-color: #499b6348;
  --bs-btn-focus-shadow-rgb: 26, 127, 59;
  --bs-btn-active-color: #212121;
  --bs-btn-active-bg: #499b6348;
  --bs-btn-active-border-color: #499b6348;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212121;
  --bs-btn-disabled-bg: #499b6323;
  --bs-btn-disabled-border-color: #499b6323;
}
.btn:focus {
  border-color: transparent;
}
