$primary: #1f9545;
$secondary: #1f95451a;
$success: #28a745;
$warning: #ffc107;
$danger: #dc3545;

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'warning': $warning,
  'danger': $danger,
);

$border-radius: 0.625;
$header-height: 60px;
$header-padding-top: 30px;
$sidebar-width: 278px;
$space: 32px;
$space-mobile: 12px;
