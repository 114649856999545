.menu-access-table{

  .table-heading{
    font-size: 12px;
    color: #0E132B;
  }

  .table-row{
    font-size: 12px;
  }

  .table-striped > tbody > tr:nth-of-type(even){
    background-color: #F9F9F9;
  }

  .table-striped > tbody > tr:nth-of-type(odd){
    background-color: white;
    --bs-table-accent-bg: white;
  }
}