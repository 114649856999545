.volta-status{
    &.container-status{
        padding: 3px 10px;
        // padding-left: 10px;
        // padding-right: 3px;
        // padding-top: 3px;
        // padding-bottom: 3px;
        width: max-content;
        border-radius: 100px;
        &-positive{
            @extend .container-status;
            background: #F2FDF6;
            &-withverifybutton{
                @extend .container-status;
                @extend .container-status-positive;
                padding-left: 10px;
                padding-right: 3px;
                padding-top: 3px;
                padding-bottom: 3px;
            }
        }
        &-negative{
            @extend .container-status;
            background: #FFF0F0;
            &-withverifybutton{
                @extend .container-status;
                @extend .container-status-negative;
                padding-left: 10px;
                padding-right: 3px;
                padding-top: 3px;
                padding-bottom: 3px;
            }
        }
        &-neutral{
            @extend .container-status;
            background: #FFF0F0;
            &-withverifybutton{
                @extend .container-status;
                @extend .container-status-negative;
                padding-left: 10px;
                padding-right: 3px;
                padding-top: 3px;
                padding-bottom: 3px;
            }
        }
        .text-status{
            display: inline-block;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            margin: 0;
            &-positive{
                @extend .text-status;
                color: #126234;
            }
            &-negative{
                @extend .text-status;
                color: #FF5353;
            }
            &-neutral{
                @extend .text-status;
                color: #FF8A00;
            }
        }
        .btn-status{
            background: #D7D7D7;
            border-radius: 100%;
            border: none;
            margin-left: 12px;
            padding: 2px 8px;;
            &-positive{
                @extend .btn-status;
                background: #D7D7D7;
            }
            &-negative{
                @extend .btn-status;
                background: #4F7BF6;
            }
            &-neutral{
                @extend .btn-status;
                background: #FFF4E7;
            }
            img{
                vertical-align: middle;
            }
        }
    }
    &.container-status-positive-custom{

    }
    &.container-status-negative-custom{
        
    }
}

.toggle-status-button{
    background: white !important;
}