@import './variable';

.form-control,
.form-select {
  color: #212221;
  background-color: #f7f9f7;
  font-size: 0.75rem;
  font-weight: 500;
  border: 0;
  border-radius: 10px;
  height: 40px;
}
.form-control:focus,
.form-select:focus {
  background-color: #f7f9f7;
  color: #212221;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  border: 1px solid #126133;
  border-radius: 10px;
}
.btn {
  border-radius: 10px;
  font-size: 0.875rem;
}
.btn:focus {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  border: 1px solid #126133;
  border-radius: 10px;
}

.form-control:disabled,
.form-select:disabled {
  background-color: #f1f1f1;
}

.btn-primary,
.btn-primary:hover {
  color: #fff !important;
}

.btn-secondary,
.btn-secondary:hover {
  color: $primary !important;
}
