@import './variable';

@import 'node_modules/bootstrap/scss/bootstrap';

@import './fonts';

@import './background';

@import './color';

@import './form';

@import './swall';

@import './card';

@import './button';

@import './badge';
