.modal.fade .modal-dialog.volta-modal {
  @media screen and (max-width: 543px) {
    bottom: -100%;
    transform: translate(0, 0);
    transition: transform 0.2s;
  }
}

.modal.show .modal-dialog.volta-modal {
  @media screen and (max-width: 543px) {
    top: 100%;
    transform: translate(0, -100%);
    transition: transform 0.2s;
    &.overflow {
      top: 150%;
    }
  }
}
.modal.show .modal-dialog.volta-modal.without-transform {
  top: unset;
  bottom: unset;
  transform: unset;
}

.volta-modal {
  @media screen and (max-width: 543px) {
    h6 {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      text-transform: capitalize;
      color: #126133;
    }
  }

  .modal-content {
    border-radius: 20px;
    padding: 0px 12px;
    // height: 597px;
    // overflow-y: auto;

    // /* width */
    // &::-webkit-scrollbar {
    //   width: 0px;
    //   background-color: transparent;
    // }

    .modal-body {
      .volta-modal-header {
        @media screen and (max-width: 543px) {
          height: 35px;
        }
        height: 50px;
      }

      .volta-modal-title {
        font-size: 20px;
        font-weight: bold;
        &-centered {
          font-size: 20px;
          font-weight: bold;
          text-align: center;
        }
      }

      .volta-modal-body {
        @media screen and (max-width: 543px) {
          margin-top: 0;
        }
        margin-top: 20px;

        .form-group {
          margin-bottom: 20px;

          label {
            color: #858585;
            font-size: 12px;
          }

          input:not([type='checkbox']) {
            height: 40px;
            margin-top: 12px;
          }
        }

        .btn-modal-submit {
          background-color: #1f9545;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 46px;
          font-size: 14px;
          color: #ffffff;
          margin-top: 47px;
          border-radius: 2rem;
          font-weight: 600;
        }
        .btn-modal-submit:hover {
          background-color: #1d8a4c;
        }
        .btn-modal-submit:active {
          background-color: #15713d;
        }
      }
    }
  }
}
