@mixin box-card-form{
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}

@mixin outer-card-form{
  border-radius: 10px;
  border-color: #c9c9c9;
  border-width: 1px;
  border-style: solid;
}

.form-content-alternative {
    background-color: white;
    min-height: 50px;
    padding: 30px 30px 40px;
  
    .form-title {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-transform: capitalize;
      color: #212221;
      @media screen and (max-width: 543px) {
        font-size: 14px;
      }
      @media screen and (max-width: 280px) {
        font-size: 12px;
      }
    }
  
    .form-data {
      margin-top: 30px;
      margin-right: 25px;
  
      .form-group {
        margin-bottom: 20px;
  
        label {
          color: #858585;
          font-size: 12px;
          @media screen and (max-width: 543px) {
            font-size: 10px;
          }
          @media screen and (max-width: 280px) {
            font-size: 18px;
          }
        }
  
        input {
          height: 40px;
          margin-top: 12px;
        }
  
        input,
        select,
        textarea {
          font-size: 12px;
          @media screen and (max-width: 543px) {
            font-size: 10px;
          }
          @media screen and (max-width: 280px) {
            font-size: 8px;
          }
        }
      }

      .form-data-alternative{
        .form-group {
          margin-bottom: 20px;
    
          label {
            color: #858585;
            font-size: 12px;
            @media screen and (max-width: 543px) {
              font-size: 10px;
            }
            @media screen and (max-width: 280px) {
              font-size: 8px;
            }
          }
    
          input {
            height: 40px;
            margin-top: 12px;
          }
    
          input,
          select,
          textarea {
            font-size: 12px;
            @media screen and (max-width: 543px) {
              font-size: 10px;
            }
            @media screen and (max-width: 280px) {
              font-size: 8px;
            }
          }
        }
      }
    }
  
    .info-box {
      display: flex;
      flex: 1;
      padding: 15px;
      border: 1px #eeeeee solid;
      font-size: 12px;
      @media screen and (max-width: 543px) {
        font-size: 10px;
      }
      @media screen and (max-width: 280px) {
        font-size: 8px;
      }
  
      background: #F1F1F1;
      border-radius: 10px;
      font-weight: 500;
  
      .title-1,
      .title-2 {
        margin: 0;
      }
  
      .title-1 {
        flex: 1;
        color: #7E7E7E;
      }
  
      .title-2 {
        flex: 2;
        color: #212221;
        text-align: end;
      }
  
      &-row{
        padding-bottom: 13px;
        border-bottom: 1.2px solid #E0E0E0;
      }
    }
  
    .motor-volta {
      width: 197.51px;
      height: 140px;
    }
  
    .configuration {
      margin-top: 37px;
    }
  
    .btn-form-submit {
      background-color: #1f9545;
      border-radius: 5px;
      color: white;
      font-size: 14px;
      width: 200px;
      height: 46px;
      border-radius: 2rem;
      font-weight: 600;
      @media screen and (max-width: 543px) {
        font-size: 12px;
      }
      @media screen and (max-width: 280px) {
        font-size: 10px;
      }
    }
  }
  