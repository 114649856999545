.input-check-box-group-action-table{

    h3{
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 16px;
    }
  
    .group-checkbox{
      margin-right: 12px;
    }
  
    label{
      font-size: 12px;
      margin: 0;
      cursor: pointer;
    }
    
  }