.volta-tooltip-wrapper {
  display: inline-block;
  position: relative;

  .volta-tooltip-content {
    position: absolute;
    left: 105%;
    top: 50%;
    transform: translateY(-50%);
    padding: 10px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);
    border: 1px solid rgba(166, 166, 166, 0.1);
  }
}