.volta-checkbox-action-table-label{
    background: #F7F9F7;
    border-radius: 10px;
    padding: 15px 15px;
    width: 100%;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
}
.volta-checkbox-action-table-label.selected{
    @extend .volta-checkbox-action-table-label;
    background: #F7F9F7;
    border: 1px solid #1F9545;
    border-radius: 10px;
    filter: none;
}