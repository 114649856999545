.input-image-alternative {
    border-radius: 10px;
    border-width: 1px;
    border-style: solid;
    border-color: #eeeeee;
    // flex: 1;
    min-height: 95px;
    max-width: 95px;
    overflow: hidden;
    background: #f7f9f7;
  
    .image {
      flex: 1;
      height: inherit;
      border-radius: inherit;
      object-fit: contain;
      width: 100%;
      text-align: center;
    }
  }
  
  .input-image-alternative.error {
    border-color: red;
  }
  .btn-upload {
    background-color: #ffe9d8;
    color: #f08633;
    font-weight: 600;
    font-size: 0.75rem;
    border-radius: 2rem;
    padding: 0.5rem 2rem;
  }
  .btn-upload:hover {
    color: #f08633 !important;
    background-color: #f8dbc5 !important;
  }
  .btn-upload:focus {
    box-shadow: 0 0 0 0.2rem rgba(240, 119, 22, 0.1);
  }
  