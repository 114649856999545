@mixin text-autocomplete-main{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}

.text-error{
  font-size: 12px;
  margin-top: 3px;
}

.text-status{
  padding: 5px;
  font-size: 12px;
  margin-top: 3px;
}

.btn-view-password{
  height: 40px;
  margin-top: 12px !important;

  img{
    width: 25px;
    height: 25px;
  }
}

.btn-verify{
  height: 40px;
  margin-top: 12px !important;
  margin-left: -96px !important;
  padding-left: 30px;
  padding-right: 30px;
  background: #D9EDDF;
  border-radius: 0px 9px 9px 0px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #1F9545;
}

.btn-verify:hover{
  @extend .btn-verify;
  background: #cce9d5 !important;
  color: #1F9545 !important;
}

.btn-verify:disabled{
  @extend .btn-verify;
  background: #bcc7bf !important;
  color: rgb(77, 70, 70) !important;
  border-color: white;
}

.autocomplete{
  position: relative;

  .inputautocomplete-list{
    position: absolute;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08);
    border-radius: 0px 0px 10px 10px;
    z-index: 99;
    left: 0;
    right: 0;
    max-height: 250px;
    overflow-y: auto;
    background-color: #f7f9f7;
    margin-top: -20px;
  
    div{
      padding: 10px;
      cursor: pointer;
      background-color: #fff; 
      border-bottom: 1px solid #d4d4d4; 
      text{
        @include text-autocomplete-main();
    }
    }
    div:hover{
      background-color: #f7f9f7 !important;
      border-color: #f7f9f7 !important;
      color: #212221 !important;
    }
    .txt-no-data{
      @include text-autocomplete-main();
      font-weight: 400;
      color: #7E7E7E;
      text-align: center;
    }
    .txt-no-data:hover{
      @include text-autocomplete-main();
      font-weight: 400 !important;
      color: #7E7E7E !important;
      background-color: #fff !important; 
      text-align: center;
    }
  }
}

.cta-reset-password{
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-decoration-line: underline;
  color: #1F9545;
}
.cta-reset-password:hover{
  color: #1c7438;
}

@mixin container-status-reset {
  padding: 3px 10px;
  width: max-content;
  border-radius: 100px;
}

@mixin text-status {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}

.status-card-positive{
 @include container-status-reset;
 background: #FAFAFA;
}

.text-card-positive{
  @include text-status;
  color: #7E7E7E;
}

label.label-input-horizontal{
  font-weight: 500 !important;
  color: #212121 !important;
}