.icon-dialog{
    .modal-body{
      padding: 20px 10px;
      .body-icon-dialog{
        text-align: center;
        .title-modal{
          text-align: center;
        }
      }
    }
  
    .title-modal{
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: #212221;
      //new style
      line-height: 20px;
      text-align: center;
      margin-bottom: 15px;
    }
  
    .desc-modal{
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 23px;
      color: #424942;
      // new style
      font-size: 14px;
      text-align: center;
      line-height: 17px;
      font-weight: 400;
    }
  
    .volta-modal-header{
      height: 20px !important;
    }
  
    .submit-content {
      display: flex;
      justify-content: center;
      padding: 20px;
      .btn-submit,
      .btn-cancel {
        @media screen and (max-width: 1024px) {
          width: 100%;
        }
        width: 40%;
        height: 40px;
        //new style
        width: 50%;
  
        p {
          color: white;
          font-size: 14px;
          margin: 0;
        }
      }
  
      .btn-submit {
        @media screen and (max-width: 1024px) {
          width: 100%;
          margin: 0;
        }
        width: 60%;
        background-color: #1F9545;
        font-size: 0.75rem;
        padding: 9px 18px;
        font-weight: 600;
        color: white;
        border-radius: 50px;
        margin-left: 5%;
        // new style
        width: 50%;
      }
  
      .btn-cancel {
        background-color: rgba(31, 149, 69, 0.17);
        font-size: 0.75rem;
        padding: 9px 18px;
        font-weight: 600;
        border-radius: 50px;
        // new style
        margin-right: 12px;
        p{
          color: #1F9545
        }
      }
    }
  }