.width-5 {
  width: 5%;
}

.width-8 {
  width: 8%;
}

.width-10 {
  width: 10%;
}

.width-15 {
  width: 15%;
}

.width-20 {
  width: 20%;
}

.width-30 {
  width: 30%;
}

.volta-table {
  border: 1px solid rgba(127, 146, 135, 0.2);
  border-radius: 6px 6px 15px 15px;
  border-radius: 5px;
  overflow-x: auto;

  .react-bs-table-no-data {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #7e7e7e;

    padding-top: 20px;
    padding-bottom: 20px;

    @media screen and (max-width: 543px) {
      font-size: 10px;
    }
    @media screen and (max-width: 280px) {
      font-size: 8px;
    }
  }

  table {
    table-layout: auto;
    margin-bottom: 0;
  }

  .table-heading {
    background-color: #f6f6f6;

    th {
      color: #20553e;
      font-size: 0.75rem;
      font-weight: 600;
      border-bottom: 0;
      min-height: 60px;
      padding-top: 20px;
      padding-bottom: 20px;
      position: relative;
      @media screen and (max-width: 543px) {
        font-size: 10px;
      }
      @media screen and (max-width: 280px) {
        font-size: 8px;
      }
    }

    th:after {
      height: 60%;
      width: 1px;
      position: absolute;
      top: 20%;
      bottom: 0;
      right: 0;
      content: '';
      background-color: rgba(127, 146, 135, 0.2);
    }
    th:last-child:after {
      background-color: transparent;
    }
  }

  .table-row {
    line-height: 1.5;
    color: #424942 !important;
    font-size: 0.75rem;
    font-weight: 600;
    @media screen and (max-width: 543px) {
      font-size: 10px;
    }
    @media screen and (max-width: 280px) {
      font-size: 8px;
    }
    td {
      border-bottom: 0;
      padding-top: 16px;
      padding-bottom: 16px;
      vertical-align: middle;
    }
    p {
      margin-bottom: 0;
    }
  }
  .table-row:not(:last-child) {
    border-bottom: 1px solid rgba(127, 146, 135, 0.2);
  }
}

.table-content {
  .cari-title {
    margin-right: 20px;
    color: #575454;
    margin-bottom: 0;
    letter-spacing: 0px;
    padding-top: 6px;
  }
  .search-and-filter {
    display: flex;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }
  .search-form-group {
    position: relative;
    flex: 1;
    img {
      position: absolute;
      top: 33%;
      z-index: 1;
      left: 20px;
      width: 13px;
      height: 13px;
    }
    input {
      width: 374px;
      background-color: #edf7f1;
      color: #0b5f2f;
      border-radius: 24px;
      padding: 12px 20px;
      position: relative;
      padding-left: 46px;
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }
    input::placeholder {
      color: #126235;
    }
  }

  .filter-button {
    background-color: #edf7f1;
    border-radius: 100%;
  }

  .cari-button,
  .add-button,
  .add-xls {
    background-color: #ff8000;
    border-radius: 5px;
  }

  .cari-button {
    width: 40px;
    height: 40px;
    margin-left: 12px;
    border: none;
  }

  .add-xls {
    color: white;
    padding: 0;
    margin-left: 10px;

    p {
      font-size: 14px;
      margin: 0;
      @media screen and (max-width: 543px) {
        font-size: 12px;
      }
      @media screen and (max-width: 280px) {
        font-size: 10px;
      }
    }
  }

  .add-button {
    font-size: 0.75rem;
    padding: 9px 18px;
    font-weight: 600;
    background-color: #1f9545;
    color: white;
    border-radius: 18px;
    @media screen and (max-width: 543px) {
      font-size: 12px;
    }
    @media screen and (max-width: 280px) {
      font-size: 10px;
    }

    @media screen and (max-width: 1024px) {
      width: 100%;
    }

    .plus {
      height: 14px;
    }
    span {
      vertical-align: middle;
      white-space: nowrap;
      margin-left: 6px;
    }
  }

  .add-button:hover {
    font-size: 0.75rem;
    padding: 9px 18px;
    font-weight: 600;
    background-color: #1d8a4c;
    color: white;
    border-radius: 18px;
    @media screen and (max-width: 543px) {
      font-size: 12px;
    }
    @media screen and (max-width: 280px) {
      font-size: 10px;
    }

    @media screen and (max-width: 1024px) {
      width: 100%;
    }

    .plus {
      height: 14px;
      margin-right: 6px;
    }
    span {
      vertical-align: middle;
      white-space: nowrap;
    }
  }

  .add-xls {
    width: 170px;
    height: 40px;
    // margin-left: 8px;
    padding-right: 14px;
    padding-left: 12.24px;
  }

  .add-button.secondary {
    color: #ff8000;
    background-color: #ffe9d8;
  }
  .add-button.secondary:hover {
    color: #ff8000;
    background-color: #f8ddc8;
  }
}

.detail-link {
  color: #4f7bf6;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  border: none !important;
  box-shadow: none !important;
  font-size: 0.75rem;
}
.detail-link:hover {
  color: #325fda !important;
}

.success-text,
.failed-text,
.secondary-text,
.warning-text {
  font-weight: bold;
  font-size: 12px;
  border-radius: 14px;
  white-space: nowrap;
  padding: 4px 13px;
  display: inline-block;
  @media screen and (max-width: 543px) {
    font-size: 10px;
  }
  @media screen and (max-width: 280px) {
    font-size: 8px;
  }
}

.success-text {
  color: #0a7d3a;
  background: #f2fdf6;
}

.failed-text {
  color: #ff5353;
  background: #fff0f0;
}

.warning-text {
  color: #ff8a00;
  background: #fff4e7;
}

.secondary-text {
  color: #545454;
  background: #4249421A;
}

.filter-content {
  width: 100%;
  height: 90%;

  .filter-form {
    flex: 1;
  }

  .submit-content {
    display: flex;
    .btn-submit,
    .btn-reset {
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
      width: 40%;
      height: 40px;

      p {
        color: white;
        font-size: 14px;
        margin: 0;
        @media screen and (max-width: 543px) {
          font-size: 12px;
        }
        @media screen and (max-width: 280px) {
          font-size: 10px;
        }
      }
    }

    .btn-submit {
      @media screen and (max-width: 1024px) {
        width: 100%;
        margin: 0;
      }
      width: 60%;
      background-color: #1f9545;
      font-size: 0.75rem;
      padding: 9px 18px;
      font-weight: 600;
      color: white;
      border-radius: 50px;
      margin-left: 5%;
      @media screen and (max-width: 543px) {
        font-size: 10px;
      }
      @media screen and (max-width: 280px) {
        font-size: 8px;
      }
    }

    .btn-reset {
      background-color: rgba(31, 149, 69, 0.17);
      font-size: 0.75rem;
      padding: 9px 18px;
      font-weight: 600;
      border-radius: 50px;
      @media screen and (max-width: 543px) {
        font-size: 12px;
      }
      @media screen and (max-width: 280px) {
        font-size: 10px;
      }
      p {
        color: #1f9545;
      }
    }
  }

  .form-label {
    color: #858585;
    font-size: 12px;
    @media screen and (max-width: 543px) {
      font-size: 10px;
    }
    @media screen and (max-width: 280px) {
      font-size: 8px;
    }
  }

  .form-control {
    height: 40px;
  }
}
