@mixin box-card-form {
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  margin-bottom: 30px !important;
}

@mixin outer-card-form {
  border-radius: 10px;
  border-color: #c9c9c9;
  border-width: 1px;
  border-style: solid;
}

.form-content {
  &.box-card {
    @include box-card-form();
  }
  &.outer-card {
    @include outer-card-form();
  }
  background-color: white;
  min-height: 50px;
  padding: 30px 30px 40px;

  .form-title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize;
    color: #212221;
  }

  .form-title-alternative {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 16px;
    @media screen and (max-width: 543px) {
      font-size: 14px;
    }
    @media screen and (max-width: 280px) {
      font-size: 12px;
    }
  }

  .form-data {
    // margin-top: 30px;
    margin-right: 25px;

    .form-group {
      margin-bottom: 20px;

      label {
        color: #858585;
        font-size: 12px;
      }

      input {
        height: 40px;
        margin-top: 12px;
      }

      input,
      select,
      textarea {
        font-size: 12px;
      }
    }
  }

  .info-box {
    display: flex;
    flex: 1;
    padding: 15px;
    font-size: 12px;

    background: #f7f9f7;
    border-radius: 10px;
    font-weight: 500;

    .title-1,
    .title-2 {
      margin: 0;
    }

    .title-1 {
      flex: 1;
      color: #7e7e7e;
    }

    .title-2 {
      flex: 2;
      color: #212221;
      text-align: end;
    }

    &-row {
      padding-bottom: 13px;
      border-bottom: 1.2px solid #e0e0e0;
    }
  }

  .motor-volta {
    width: 197.51px;
    height: 140px;
  }

  .configuration {
    margin-top: 37px;
  }

  .btn-form-submit {
    background-color: #1f9545;
    border-radius: 5px;
    color: white;
    font-size: 14px;
    min-width: 200px;
    padding: 0 24px;
    height: 46px;
    border-radius: 2rem;
    font-weight: 600;
  }
  .btn-form-submit:hover {
    color: white;
    background-color: #1d8a4c;
  }
  .btn-form-secondary {
    background-color: rgba(31, 149, 69, 0.17);
    border-radius: 5px;
    color: #1f9545;
    font-size: 14px;
    min-width: 200px;
    padding: 0 24px;
    height: 46px;
    border-radius: 2rem;
    font-weight: 600;
  }
  .btn-form-secondary:hover {
    color: #1f9545;
    background-color: rgba(31, 149, 68, 0.27);
  }
}
