.swal-modal {
  min-width: 293px;
  width: max-content;
  border-radius: 16px;
}

.swal-title {
  margin: 0px;
  font-size: 14px;
  font-weight: 500;
  color: #212221;
  margin-bottom: 0;
}

.swal-text {
  text-align: center;
  font-size: 12px;
}

.swal-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  padding-top: 0;
}

.swal-button {
  padding: 7px 19px;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 600;
  border: none;
  min-width: 100px;
  height: 40px;
  margin-top: 20px;
}
.swal-button--confirm {
  background-color: #1f9545;
  color: #ffffff;
  &:hover {
    background-color: #1d8a4c;
  }

  &:not([disabled]):hover {
    background-color: #1d8a4c;
  }
}

.custom-swal-success {
  .swal-text {
    padding-right: 16px;
    padding-left: 16px;
    display: block;
    text-align: center;
    color: #585858;
    font-size: 14px;
    font-weight: 400;
    max-width: unset !important;
  }
}

.custom-swal-failed {
  .swal-text {
    padding-right: 16px;
    padding-left: 16px;
    display: block;
    text-align: center;
    color: #212221;
    font-size: 14px;
    font-weight: 400;
    max-width: unset !important;
  }
}
