@import '../../../assets/scss/variable.scss';

.header {
  position: fixed;
  height: $header-height;
  display: inline-flex;
  align-items: center;
  width: 100%;
  background-color: #126235;
  z-index: 2;
  top: 0;

  .title {
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    margin: 0;
    @media screen and (max-width: 543px) {
      font-size: 14px;
    }
    @media screen and (max-width: 280px) {
      font-size: 12px;
    }
  }
  .btn-menu {
    margin-left: auto;
  }

  .header-left {
    width: $sidebar-width;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    display: flex;
    padding-left: $space;
    padding-right: 14px;
  }
  .header-right {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    padding-left: $space;
    padding-right: $space;
  }
  .profile {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .profile-picture {
      width: 32px;
      height: 32px;
      border-radius: 40px;
      margin-right: 12px;
      border-radius: 32px;
      background-color: gray;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    .bell-icon {
      margin-right: 30px;
      width: 24px;
      height: 28px;
    }

    .user-name {
      font-weight: bold;
      font-size: 0.875rem;
      letter-spacing: 0px;
      color: #fff;
      margin-bottom: 0;
    }
  }
  .logo .mobile {
    display: none;
  }
  .header-left .title {
    display: none;
  }
  @media screen and (max-width: 1024px) {
    .header-left {
      width: 100%;
      border-right: none;
    }
    .header-right {
      display: none;
    }
    .logo .desktop {
      display: none;
    }
    .logo .mobile {
      display: block;
    }
    .header-left .title {
      display: block;
      margin-left: auto;
      margin-right: auto;
      line-height: 2;
    }
    .btn-menu {
      margin-left: initial;
    }
  }
}
