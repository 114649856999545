@media screen and (max-width: 543px) {
    .modal-dialog{
        margin: 0;
        min-height: -webkit-fill-available;
        align-items: flex-end;
    }
    .volta-modal .modal-content{
        border: none;
        border-radius: 20px 20px 0 0 !important;
    }
 }