@import '../../../assets/scss/variable';

// ------------------------------------------Stepper------------------------------------------------ //

// span.StepButtonContent.completed{
//   content: 'P' !important;
// }

.btn-upload{
  // @media screen and (max-width: 1024px) {
  //   width: 100%;
  // }
  width: 100%;
}

  .group-checkbox.selected{
    background: #126235;
  }
  
  #RFS-StepperContainer.volta-stepper{
    background: #FFFFFF;
    @media screen and (max-width: 1024px) {
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.03);
    }
    padding: 16px;
    margin-top: -($header-padding-top);
    margin-left: -($space-mobile);
    margin-right: -($space-mobile);
    #RFS-Label{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #126235;
        margin-top: 8px;
        @media screen and (max-width: 543px) {
          font-size: 10px;
        }
        @media screen and (max-width: 280px) {
          font-size: 8px;
        }
      }
      
      #RFS-StepButton.completed{
        content: url('../../../assets/svg/stepper-valid.svg');
      }
  }
  
  .volta-container-step{
    margin-top: 5px;
    .btn-stepper{
        background-color: #1f9545;
        border-radius: 5px;
        color: white;
        font-size: 14px;
        height: 46px;
        border-radius: 2rem;
        font-weight: 600;
        width: 224px;
        @media screen and (max-width: 543px) {
          font-size: 12px;
        }
        @media screen and (max-width: 280px) {
          font-size: 10px;
        }
        @media screen and (max-width: 1024px) {
          width: 100%;
        }
      }
    
      .btn-stepper:hover{
        background-color: #1f9545;
        color: white;
      }

      .btn-stepper-back{
        background: rgba(31, 149, 69, 0.17);
        border-radius: 50px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #1F9545;
        height: 46px;
        border-radius: 2rem;
        font-weight: 600;
        width: 163px;
        @media screen and (max-width: 543px) {
          font-size: 12px;
        }
        @media screen and (max-width: 280px) {
          font-size: 10px;
        }
        @media screen and (max-width: 1024px) {
          width: 100%;
        }
      }

      .btn-stepper-back:hover{
        background: rgba(31, 149, 69, 0.17);
        color: #1F9545;
      }
  }
  
  // .content {
  //   .main-content {
  //     margin-top: $header-height !important;
  //     @media screen and (max-width: 1024px) {
  //       padding: 0 !important;
  //     }
  //   }
  // }
  
  // .StepButton-d0-0-2-6{
  //   border:1px solid #126235 !important;
  // }