.group-checkbox {
  cursor: pointer;
  width: 20px;
  height: 20px;
  border: 1px #c9c9c9 solid;
  border-radius: 4px;
}
.group-checkbox img {
  margin-top: 1px;
  margin-left: 1px;
}
.group-checkbox.selected {
  background: #126235;
}
